html.dark {
  font-family: 'Droid Arabic Kufi', serif;
  background-color: rgb(15, 23, 42);
  /* background-color: rgb(93, 123, 158); */
}
.big-box {
  /* box-shadow: rgb(163, 189, 184) 0px 0px 0px 4px; */
  background-color: rgba(11, 72, 126, 0.25);
  border-radius: 15px;
}
body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(28, 32, 43, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #2c2d38;
  outline: 1px solid rgb(37, 45, 53);
}
.counter {
  font-size: 10rem;
}

.excluded {
  background-color: '#340055' !important;
}
